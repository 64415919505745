import React, { useState } from 'react'
import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material'
import Swal from 'sweetalert2'
import { DateFieldComponent } from '../../pages/outlay/commons/DateFieldComponent'
import CustomTextField from '../CustomTextField/CustomTextField'
import { createHoliday } from '../../config/axios'
import dayjs from 'dayjs'
import esLocale from 'dayjs/locale/es'

dayjs.locale(esLocale)

export const HolidaysForm = () => {
  const [holidays, setHolidays] = useState({
    date: '',
    day: '',
    event: '',
  })

  const handleChange = (field, value) => {
    if (field === 'date') {
      const dayName = dayjs(value).locale('es').format('dddd')
      setHolidays({ ...holidays, date: value, day: dayName })
    } else if (field === 'event') {
      setHolidays({ ...holidays, event: value })
    }
  }

  const submitForm = async (e) => {
    e.preventDefault()
    try {
      const { msg } = await createHoliday(
        '/holidays-api/crear-feriado',
        holidays,
        localStorage.getItem('token')
      )

      Swal.fire({
        icon: 'success',
        title: '¡Registro exitoso!',
        text: msg,
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: error.message,
      })
    }
  }

  return (
    <form autoComplete="off" noValidate onSubmit={submitForm}>
      <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
        <CardHeader
          title="En caso de dar días libres, debes registrarlo en este formulario"
          subheader="No debes ingresar los feriados legales"
        />
        <CardContent sx={{ pt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DateFieldComponent
                label="Ingrese fecha"
                value={holidays.date}
                onChange={(value) => handleChange('date', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                required
                label="Día"
                value={holidays.day}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                required
                label="Motivo"
                value={holidays.event}
                onChange={(e) => handleChange('event', e.target.value)} 
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" marginTop={3}>
            <Button type="submit" variant="contained">
              Registrar
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}
