import { processDedicatedValue } from './processDedicatedValue'

/**
 * Filtra y procesa un array de dedicaciones.
 * La función primero filtra las dedicaciones que tienen una consolidación de 0 (false),
 * luego mapea el array y procesa el valor de 'dedicated' en cada objeto de dedicación.
 *
 * @param {Array} dedications - Un array de objetos que representan las dedicaciones.
 * Cada objeto de dedicación tiene propiedades de 'consolidation' y 'dedicated'.
 *
 * @returns {Array} Un nuevo array de objetos de dedicación que han sido filtrados y procesados.
 * Cada objeto tiene las mismas propiedades que en la entrada, pero el valor de 'dedicated'
 * ha sido procesado por la función `processDedicatedValue`.
 */
export const filterAndProcessDedications = (dedications) => {
  const filteredDedications = dedications.filter(
    (dedication) => dedication.consolidation === false
  )
  console.log(filteredDedications)

  return filteredDedications.map((dedication) => ({
    ...dedication,
    dedicated: processDedicatedValue(dedication.dedicated),
  }))
}
