import React from 'react'
import { TextField } from '@mui/material'

const CustomTextField = ({
  register,
  required,
  error,
  label,
  type = 'text',
  placeholder,
  autocomplete,
  ...rest
}) => (
  <TextField
    fullWidth
    label={label}
    {...register}
    required={required}
    error={Boolean(error)}
    type={type}
    placeholder={placeholder}
    autoComplete={autocomplete}
    {...rest}
  />
)

export default CustomTextField
