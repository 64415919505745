/**
 * Genera las propiedades de accesibilidad para una pestaña.
 * @param {number} index - Índice de la pestaña.
 * @returns {object} - Las propiedades de accesibilidad.
 */
export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
