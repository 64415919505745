export const removeDots = (data) => {
  const regex = /[\.\$]/g
  return Object.keys(data).reduce((updatedData, key) => {
    if (typeof data[key] === 'string') {
      updatedData[key] = data[key].replace(regex, '')
    } else {
      updatedData[key] = data[key]
    }
    return updatedData
  }, {})
}
