import { useCallback, useState } from 'react'

export const useSelectedProject = (initialValue) => {
  const [selectProject, setSelectProject] = useState(initialValue)

  const handleChange = useCallback((event) => {
    setSelectProject(event.target.value)
  }, [])

  return [selectProject, handleChange]
}
