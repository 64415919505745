import React from 'react'
import { TextField } from '@mui/material'

export const RenderSelectField = ({
  label,
  options,
  register,
  error,
  value,
  onChange,
}) => (
  <TextField
    fullWidth
    {...register}
    select
    SelectProps={{ native: true }}
    helperText={label}
    error={Boolean(error)}
    value={value}
    onChange={onChange}
  >
    <option defaultValue>Seleccione una opción</option>
    {options.map((option) => (
      <option key={option.id} value={option.id}>
        {option.name ||
          option.client.clientName + '   - ' + option.project_name}
      </option>
    ))}
  </TextField>
)
