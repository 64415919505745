import React from 'react'

import { useLocation } from 'react-router-dom'
import {
  AccountTreeIcon,
  AddBoxIcon,
  AddBusinessIcon,
  AddToPhotosIcon,
  DashboardIcon,
  EventIcon,
  ExitToAppIcon,
  ManageAccountsIcon,
  MonetizationOnIcon,
  PersonAddAlt1Icon,
  PlaylistRemoveIcon,
  RemoveRedEyeIcon,
  RequestQuoteIcon,
} from './images.imports'

export const adminPath = [
  {
    index: 1,
    title: 'Inicio',
    path: '/inicio',
    icon: <DashboardIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 2,
    title: 'Crear cliente',
    path: '/crear-cliente',
    icon: <PersonAddAlt1Icon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 3,
    title: 'Crear proyecto',
    path: '/proyectos/crear-proyecto',
    icon: <AddToPhotosIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 4,
    title: 'Proyectos',
    path: '/proyectos',
    icon: <AccountTreeIcon sx={{ color: '#fafafa' }} />,
  },

  {
    index: 5,
    title: 'Terminar proyecto',
    path: '/proyectos/terminar-proyecto',
    icon: <PlaylistRemoveIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 6,
    title: 'Agregar dedicación',
    path: '/proyectos/agregar-horas',
    icon: <AddBoxIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 7,
    title: 'Monitoreo',
    path: '/monitoreo',
    icon: <RemoveRedEyeIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 8,
    title: 'Crear usuarios',
    path: '/crear-usuario',
    icon: <PersonAddAlt1Icon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 9,
    title: 'Registrar Desembolsos',
    path: '/registrar-desembolsos',
    icon: <RequestQuoteIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 10,
    title: 'Registrar Ingresos',
    path: '/registrar-ingresos',
    icon: <AddBusinessIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 11,
    title: 'Registrar Sueldos',
    path: '/registrar-sueldos',
    icon: <MonetizationOnIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 12,
    title: 'Añadir Día Libre',
    path: '/dia-libre',
    icon: <EventIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 13,
    title: 'Editar mi perfil',
    path: '/editar-perfil',
    icon: <ManageAccountsIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 14,
    title: 'Cerrar sesión',
    path: '/logout',
    icon: <ExitToAppIcon sx={{ color: '#fafafa' }} />,
  },
]

export const directoraEjecutivaPath = [
  {
    index: 1,
    title: 'Inicio',
    path: '/inicio',
    icon: <DashboardIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 2,
    title: 'Crear cliente',
    path: '/crear-cliente',
    icon: <PersonAddAlt1Icon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 3,
    title: 'Crear proyecto',
    path: '/proyectos/crear-proyecto',
    icon: <AddToPhotosIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 4,
    title: 'Proyectos',
    path: '/proyectos',
    icon: <AccountTreeIcon sx={{ color: '#fafafa' }} />,
  },

  {
    index: 5,
    title: 'Terminar proyecto',
    path: '/proyectos/terminar-proyecto',
    icon: <PlaylistRemoveIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 6,
    title: 'Agregar dedicación',
    path: '/proyectos/agregar-horas',
    icon: <AddBoxIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 7,
    title: 'Monitoreo',
    path: '/monitoreo',
    icon: <RemoveRedEyeIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 8,
    title: 'Crear usuarios',
    path: '/crear-usuario',
    icon: <PersonAddAlt1Icon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 9,
    title: 'Editar mi perfil',
    path: '/editar-perfil',
    icon: <ManageAccountsIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 10,
    title: 'Añadir Día Libre',
    path: '/dia-libre',
    icon: <EventIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 11,
    title: 'Cerrar sesión',
    path: '/logout',
    icon: <ExitToAppIcon sx={{ color: '#fafafa' }} />,
  },
]

export const ejecutivoDeCuentasPath = [
  {
    index: 2,
    title: 'Proyectos',
    path: '/proyectos',
    icon: <AccountTreeIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 3,
    title: 'Agregar dedicación',
    path: '/proyectos/agregar-horas',
    icon: <AccountTreeIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 4,
    title: 'Editar perfil',
    path: '/editar-perfil',
    icon: <ManageAccountsIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 5,
    title: 'Cerrar sesión',
    path: '/logout',
    icon: <ExitToAppIcon sx={{ color: '#fafafa' }} />,
  },
]

export const normalUsersPath = [
  {
    index: 1,
    title: 'Inicio',
    path: '/',
    icon: <DashboardIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 2,
    title: 'Agregar dedicación',
    path: '/proyectos/agregar-horas',
    icon: <AccountTreeIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 3,
    title: 'Editar perfil',
    path: '/editar-perfil',
    icon: <ManageAccountsIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 4,
    title: 'Cerrar sesión',
    path: '/logout',
    icon: <ExitToAppIcon sx={{ color: '#fafafa' }} />,
  },
]

const SideBar = () => {
  const location = useLocation()

  return (
    <div>
      {adminPath.map((item) => {
        const ActiveIcon = item.icon
        const isActive = item.path === location.pathname

        return (
          <div
            key={item.index}
            style={{ color: isActive ? 'blue' : '#fafafa' }}
          >
            <ActiveIcon sx={{ color: 'inherit' }} />
            <span>{item.title}</span>
          </div>
        )
      })}
    </div>
  )
}

export default SideBar
