import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashboardDetails from '../../sections/dashboard/DashboardDetails'
import { useNavigate } from 'react-router-dom'
import ConsolidationHoursAccordion from '../projects/common/ConsolidationHoursAccordion'
import { getDashboardQuery } from '../../config/axios'
import Swal from 'sweetalert2'

const MonitorPage = () => {
  const [dashboardUrl, setDashboardUrl] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const getDashboardByName = async () => {
      try {
        const response = await getDashboardQuery(
          '/dashboards-api/get-dashboard',
          localStorage.getItem('token'),
          'Monitoreo'
        )

        setDashboardUrl(response.dashboard.url)
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: '¡Ha ocurrido un error!',
          msg: 'No se pudo obtener el dashboard',
        })
      }
    }

    getDashboardByName()
  })

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }

    if (
      localStorage.getItem('role') !== 'admin' &&
      localStorage.getItem('role') !== 'directoraejecutiva'
    ) {
      navigate('/proyectos')
    }
  }, [navigate])

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container>
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">
              Dashboard de consolidación de trabajo
            </Typography>
          </div>
          <div>
            <Grid item={true} xs={12} md={12}>
              <Grid item={true} xs={12} md={12}></Grid>
              <DashboardDetails dashboardUrl={dashboardUrl} />
            </Grid>
            <Grid marginY={2}>
              <ConsolidationHoursAccordion />
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default MonitorPage
