import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Typography,
  Box,
} from '@mui/material'
import Swal from 'sweetalert2'
import {
  deleteDedicationQuery,
  updateDedicationQuery,
} from '../../config/axios'
import { convertDateFormat } from '../../utils/convertDateFormat'
import { ShowMessageAlert } from '../../components/common/ShowMessageAlert'
import TableHeader from './components/TableHeader'
import TableRowItem from './components/TableRowItem'

const ConsolidationHoursTable = React.memo(
  ({
    projects,
    dedication,
    setDedication,
    totalDedication,
    weeks,
    setDedicationChanged,
    nameTab,
  }) => {
    const [editingIndex, setEditingIndex] = useState(null)
    const [tempDedication, setTempDedication] = useState(null)

    const handleCellChange = (event, key) => {
      const temp = { ...tempDedication }
      temp[key] = event.target.value
      setTempDedication(temp)
    }

    const handleDeleteClick = async (id) => {
      try {
        await deleteDedicationQuery('/dedicacion-api/eliminar-dedicacion', id)
        Swal.fire({
          icon: 'success',
          title: 'Fila borrada correctamente',
        })
        setDedicationChanged(true)
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error',
        })
      }
    }

    const handleSelectorChange = (event) => {
      handleCellChange(event, 'week')
    }

    const handleEditClick = (index, row) => {
      setEditingIndex(index)
      setTempDedication(row)
    }

    const handleConfirmChanges = async () => {
      try {
        const updatedData = [...dedication]
        updatedData[editingIndex] = {
          ...tempDedication,
          week: convertDateFormat(tempDedication.week),
        }
        const response = await updateDedicationQuery(
          '/dedicacion-api/actualizar-dedicacion',
          updatedData[editingIndex]
        )
        setDedication(updatedData)
        setEditingIndex(null)
        setTempDedication(null)
        const { msg } = response.data
        ShowMessageAlert(
          'Cambio actualizado correctamente',
          'success',
          msg,
          '1500'
        )
        setDedicationChanged(true)
      } catch (error) {
        const { msg } = error.response.data
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error',
          text: msg,
        })
      }
    }

    return (
      <TableContainer
        component={Paper}
        style={{ padding: '10px', boxShadow: 'none' }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h5">Consolidación de horas</Typography>
          {nameTab !== 'Ver historial de registros' && (
            <Typography variant="h6">
              Total dedicación: {totalDedication} %
            </Typography>
          )}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader tabName="Ver historial de registros" />
          <TableBody>
            {dedication.map((row, index) => (
              <TableRowItem
                key={row.id}
                row={row}
                index={index}
                editingIndex={editingIndex}
                tempDedication={tempDedication}
                weeks={weeks}
                dedication={dedication}
                handleCellChange={handleCellChange}
                handleSelectorChange={handleSelectorChange}
                handleEditClick={handleEditClick}
                handleConfirmChanges={handleConfirmChanges}
                handleDeleteClick={handleDeleteClick}
                nameTab={nameTab}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
)

export default ConsolidationHoursTable
