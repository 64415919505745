import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = ({ buttonText, redirect, text }) => {
  const navigate = useNavigate()

  const handleClick = (route) => {
    navigate(route)
  }

  const role = localStorage.getItem('role')

  return (
    <Box
      height="50vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography variant="h4"> {text} </Typography>
      <Grid container spacing={3} justifyContent="center" mt={1}>
        {role === 'directoraejecutiva' || role === 'admin' ? (
          <Grid item>
            <Button variant="contained" onClick={() => handleClick(redirect)}>
              {buttonText}
            </Button>
          </Grid>
        ) : null}
        <Grid item></Grid>
      </Grid>
    </Box>
  )
}

export default NotFound
