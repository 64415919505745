// import { useState, useEffect } from 'react'

// function useMondaysOfCurrentMonth() {
//   const [mondays, setMondays] = useState([])

//   useEffect(() => {
//     const currentDate = new Date()
//     calculateMondays(currentDate)
//   }, [])

//   const calculateMondays = (currentDate) => {
//     const year = currentDate.getFullYear()
//     const month = currentDate.getMonth()

//     // Calcular el primer día del mes actual
//     const firstDayOfMonth = new Date(year, month, 1)
//     let monday = new Date(firstDayOfMonth)

//     // Ajustar al lunes más cercano
//     monday.setDate(monday.getDate() + ((1 - monday.getDay() + 7) % 7))

//     const mondaysOfMonth = []
//     let weekId = 1

//     // Calcular todos los lunes del mes actual
//     while (monday.getMonth() === month) {
//       const formattedDate = `${monday.getDate().toString().padStart(2, '0')}-${(
//         monday.getMonth() + 1
//       )
//         .toString()
//         .padStart(2, '0')}-${monday.getFullYear()}`
//       mondaysOfMonth.push({ id: weekId, week: formattedDate })
//       monday.setDate(monday.getDate() + 7)
//       weekId++
//     }

//     // Calcular la última semana del mes anterior
//     const lastDayOfPreviousMonth = new Date(year, month, 0)
//     const lastMondayOfPreviousMonth = new Date(
//       lastDayOfPreviousMonth.getFullYear(),
//       lastDayOfPreviousMonth.getMonth(),
//       lastDayOfPreviousMonth.getDate() -
//         ((lastDayOfPreviousMonth.getDay() + 6) % 7)
//     )

//     const formattedLastMonday = `${lastMondayOfPreviousMonth
//       .getDate()
//       .toString()
//       .padStart(2, '0')}-${(lastMondayOfPreviousMonth.getMonth() + 1)
//       .toString()
//       .padStart(2, '0')}-${lastMondayOfPreviousMonth.getFullYear()}`

//     mondaysOfMonth.unshift({
//       id: weekId,
//       week: formattedLastMonday,
//     })

//     setMondays(mondaysOfMonth)
//   }

//   return mondays
// }

// export default useMondaysOfCurrentMonth
import { useState, useEffect } from 'react'

function useMondaysOfCurrentMonth() {
  const [weeks, setWeeks] = useState([])

  useEffect(() => {
    const currentDate = new Date()
    calculateWeeks(currentDate)
  }, [])

  const calculateWeeks = (currentDate) => {
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth()

    const weeksOfMonth = []

    // Calcular el primer día del mes actual
    const firstDayOfMonth = new Date(year, month, 1)
    let monday = new Date(firstDayOfMonth)

    // Ajustar al lunes más cercano
    monday.setDate(monday.getDate() + ((1 - monday.getDay() + 7) % 7))

    let weekId = 1

    // Calcular todos los lunes del mes actual
    while (monday.getMonth() === month) {
      const formattedDate = `${monday.getDate().toString().padStart(2, '0')}-${(
        monday.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}-${monday.getFullYear()}`
      weeksOfMonth.push({ id: weekId, week: formattedDate })
      monday.setDate(monday.getDate() + 7)
      weekId++
    }

    // Si el mes actual es enero, calcular los lunes del mes anterior
    if (month === 0) {
      const previousMonthYear = year - 1
      const lastDayOfPreviousMonth = new Date(previousMonthYear, 0, 0)

      // Calcular el primer lunes del mes anterior
      let lastMonday = new Date(lastDayOfPreviousMonth)
      lastMonday.setDate(lastMonday.getDate() - ((lastMonday.getDay() + 6) % 7))

      // Agregar lunes del mes anterior
      while (lastMonday.getMonth() === 11) {
        const formattedDate = `${lastMonday
          .getDate()
          .toString()
          .padStart(2, '0')}-${(lastMonday.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${lastMonday.getFullYear()}`
        weeksOfMonth.unshift({ id: weekId, week: formattedDate })
        lastMonday.setDate(lastMonday.getDate() - 7)
        weekId++
      }
    } else {
      // Calcular los lunes del mes anterior
      const previousMonth = month - 1
      const lastDayOfPreviousMonth = new Date(year, previousMonth + 1, 0)

      // Calcular el primer lunes del mes anterior
      let lastMonday = new Date(lastDayOfPreviousMonth)
      lastMonday.setDate(lastMonday.getDate() - ((lastMonday.getDay() + 6) % 7))

      // Agregar lunes del mes anterior
      while (lastMonday.getMonth() === previousMonth) {
        const formattedDate = `${lastMonday
          .getDate()
          .toString()
          .padStart(2, '0')}-${(lastMonday.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${lastMonday.getFullYear()}`
        weeksOfMonth.unshift({ id: weekId, week: formattedDate })
        lastMonday.setDate(lastMonday.getDate() - 7)
        weekId++
      }
    }

    setWeeks(weeksOfMonth)
  }

  return weeks
}

export default useMondaysOfCurrentMonth
