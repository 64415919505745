import React from 'react'

import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import CreateAccountProfile from '../../sections/account/CreateAccountProfile'
import { useEffect, useState } from 'react'
import { getRolesAndWorkday } from '../../config/axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const CreateUser = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])
  const [roles, setRoles] = useState()
  const [workday, setWorkday] = useState()

  useEffect(() => {
    async function getAllRoles() {
      try {
        const { roles, workday } = await getRolesAndWorkday(
          '/roles-api/roles',
          localStorage.getItem('token')
        )
        console.log(roles)
        setRoles(roles)
        setWorkday(workday)
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.clear()
          navigate('/iniciar-sesion')
        } else {
          const { msg } = error.response.data
          Swal.fire({
            icon: 'error',
            title: 'Ha ocurrido un error',
            text: msg,
          })
        }
      }
    }

    getAllRoles()
  }, [navigate])

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Crear usuario</Typography>
          <Grid container display="flex">
            <Grid item={true}>
              {roles && (
                <CreateAccountProfile roles={roles} workday={workday} />
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  )
}

export default CreateUser
