import React, { useState, useEffect } from 'react'
import { TextField } from '@mui/material'

const ProjectSelector = ({
  type,
  data,
  selectedProject,
  handleChange,
  weeks,
}) => {
  const [lastValue, setLastValue] = useState(selectedProject)

  useEffect(() => {
    if (selectedProject !== lastValue) {
      setLastValue(selectedProject)
    }
  }, [selectedProject, lastValue])

  const handleValueChange = (event) => {
    handleChange(event)
    setLastValue(event.target.value)
  }

  return (
    <TextField
      disabled={type === 'project'}
      label={'Seleccione una opción'}
      error={selectedProject === 'null'}
      fullWidth
      name={type}
      onChange={handleValueChange}
      required
      select
      SelectProps={{ native: true }}
      value={selectedProject || lastValue}
    >
      <option value="null"></option>

      {weeks
        ? weeks.map((week) => (
            <option key={week.id} value={week.week}>
              {week.week}
            </option>
          ))
        : data.map((project) => (
            <option
              key={project.id}
              value={type === 'week' ? project.week : project.id}
            >
              {type === 'week'
                ? project.week
                : `${project.client_name} - ${project.project_name}`}
            </option>
          ))}
    </TextField>
  )
}

export default ProjectSelector
