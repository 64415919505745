import React from 'react'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import enLocale from 'dayjs/locale/en'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

// Establecer la zona horaria
dayjs.tz.setDefault('America/New_York')

export const DateFieldComponent = ({ label, value, onChange, error }) => {
  const handleChange = (date) => {
    if (date) {
      onChange(dayjs(date).format('YYYY-MM-DD'))
    } else {
      onChange(null)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={enLocale}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label={label}
          value={value ? dayjs(value, 'YYYY-MM-DD') : null}
          onChange={handleChange}
          format="DD/MM/YYYY"
          formatLocalization={{ firstWeekDay: 1 }}
          error={error}
          renderInput={(params) => <TextField {...params} />}
          slotProps={{
            textField: {
              helperText: 'DD/MM/YYYY',
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}
