import React from 'react'
import { Box, Unstable_Grid2 as Grid } from '@mui/material'
import ProjectSelector from './common/ProjectSelector'

export const ProjectSelectorData = ({
  type,
  data,
  selectedProject,
  handleChange,
  weeks,
}) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          {data && (
            <ProjectSelector
              weeks={weeks}
              type={type}
              data={data}
              selectedProject={selectedProject}
              handleChange={handleChange}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
