import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const ConsolidationHoursAccordionSkeleton = () => {
  return (
    <Box sx={{ width: '100%', maxWidth: 800 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Skeleton sx={{ width: '33%', flexShrink: 0 }} />
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: '100%' }}>
            <Skeleton height={40} />
            <Skeleton height={40} animation="wave" />
            <Skeleton height={40} />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Skeleton sx={{ width: '33%', flexShrink: 0 }} />
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: '100%' }}>
            <Skeleton height={40} />
            <Skeleton height={40} animation="wave" />
            <Skeleton height={40} />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Skeleton sx={{ width: '33%', flexShrink: 0 }} />
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: '100%' }}>
            <Skeleton height={40} />
            <Skeleton height={40} animation="wave" />
            <Skeleton height={40} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default ConsolidationHoursAccordionSkeleton
