import React from 'react'

import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { createUserAccount } from '../../config/axios'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@mui/material'
import Swal from 'sweetalert2'
import CustomTextField from '../../components/CustomTextField/CustomTextField'
import { HomeContext } from '../../context/HomeContext'

const CreateAccountProfile = ({ roles, workday }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm()
  const [auth] = useContext(HomeContext)
  const initialUserState = {
    name: '',
    email: '',
    password: '',
    role_id: '',
    rut: '',
    active: 1,
    workday_id: '',
  }
  const [user, setUser] = useState(initialUserState)

  const watchedRole = watch('role_id', '')
  const watchedWorkday = watch('workday_id', '')

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      role_id: watchedRole,
      workday_id: watchedWorkday,
    }))
  }, [watchedRole, watchedWorkday])

  useEffect(() => {
    if (!localStorage.getItem('role')) {
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'No tienes los permisos para acceder a esta función',
      })
      navigate('/iniciar-sesion')
    }
  }, [auth.isAuth, auth.token, navigate])

  const onSubmit = async (data) => {
    try {
      await createUserAccount(
        '/users-api/crear-usuario',
        data,
        localStorage.getItem('token')
      )
      Swal.fire({
        icon: 'success',
        title: 'Usuario creado correctamente',
        showConfirmButton: false,
        timer: 1500,
      })
      setUser(initialUserState)
      reset(initialUserState)
    } catch (error) {
      console.log(error)
      const { message } = error.response.data
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: message,
      })
    }
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
        <CardHeader
          title="Crea un nuevo usuario para tu organización"
          subheader="Debes ingresar los datos en los campos respectivos"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  register={register('name', { required: true })}
                  required
                  label="Ingrese el nombre del usuario"
                  error={errors.name}
                />
                {errors.name && (
                  <span style={{ color: 'red', fontSize: '13px' }}>
                    Este campo es requerido
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  register={register('email', { required: true })}
                  required
                  label="Correo electrónico asociado"
                  error={Boolean(errors.email)}
                />
                {errors.email && (
                  <span style={{ color: 'red', fontSize: '13px' }}>
                    Este campo es requerido
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  register={register('rut', { required: true })}
                  required
                  label="Ingrese RUT"
                  placeholder="Ejemplo: 12345678-9"
                  error={Boolean(errors.email)}
                  autocomplete="userRut"
                />
                {errors.email && (
                  <span style={{ color: 'red', fontSize: '13px' }}>
                    Este campo es requerido
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  register={register('password', { required: true })}
                  required
                  type="password"
                  label="Contraseña"
                  error={Boolean(errors.password)}
                  autocomplete="current-password"
                />
                {errors.password && (
                  <span style={{ color: 'red', fontSize: '13px' }}>
                    Este campo es requerido
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={`Seleccione el cargo que tendrá ${user.name}`}
                  {...register('role_id', { required: true })}
                  select
                  SelectProps={{ native: true }}
                  error={Boolean(errors.role_id)}
                >
                  <option value="null"></option>
                  {roles.map((rol) => (
                    <option key={rol.id} value={rol.id}>
                      {rol.name}
                    </option>
                  ))}
                </TextField>
                {errors.role_id && (
                  <span style={{ color: 'red' }}>Este campo es requerido</span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={`Seleccione el horario`}
                  {...register('workday_id', { required: true })}
                  select
                  SelectProps={{ native: true }}
                  error={Boolean(errors.workday_id)}
                  name="workday_id"
                >
                  <option value="null"></option>
                  {workday.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.workday}
                    </option>
                  ))}
                </TextField>
                {errors.workday_id && (
                  <span style={{ color: 'red' }}>Este campo es requerido</span>
                )}
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button type="submit" variant="contained">
            Crear
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

export default CreateAccountProfile
