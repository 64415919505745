import React from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import CustomTextField from '../../components/CustomTextField/CustomTextField'
import { useForm } from 'react-hook-form'
import { checkToken, createClientQuery } from '../../config/axios'
import { useNavigate } from 'react-router-dom'

const CreateClient = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await checkToken(
          '/check-token',
          localStorage.getItem('token')
        )
        if (response.status === 401) {
          Swal.fire({
            icon: 'warning',
            title: 'Debes volver a iniciar sesión',
            showConfirmButton: false,
            timer: 1500,
          })
          localStorage.clear()
          navigate('/iniciar-sesion')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal al obtener los proyectos!',
        })
      }
    }

    validateToken()
  }, [navigate])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const onSubmit = async (data) => {
    try {
      const response = await createClientQuery(
        '/clientes-api/create-client',
        data
      )
      Swal.fire({
        icon: 'success',
        title: '¡Todo ha salido bien!',
        text: response.msg,
      })
      reset()
    } catch (error) {
      const { msg } = error.response.data
      Swal.fire({
        icon: 'warning',
        title: 'Ha ocurrido un error',
        text: msg,
      })
    }
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">Crear nuevo cliente</Typography>
            </div>
            <div>
              <Grid item={true} container display="flex">
                <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
                  <CardHeader
                    title="Crea un nuevo cliente para tu organización"
                    subheader="Debes ingresar los datos en los campos respectivos"
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                          <CustomTextField
                            register={register('client_name', {
                              required: true,
                            })}
                            required
                            label="Ingrese el nombre del cliente"
                            error={errors.client_name}
                          />
                          {errors.client_name && (
                            <span style={{ color: 'red', fontSize: '13px' }}>
                              Este campo es requerido
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <CustomTextField
                            register={register('client_description')}
                            label="Ingrese una descripción del cliente"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button type="submit" variant="contained">
                      Crear
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </form>
  )
}

export default CreateClient
