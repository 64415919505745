import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DeleteAccountData } from '../../sections/delete-account/DeleteAccountData'
import { useNavigate } from 'react-router-dom'
import { getAllUsersQuery } from '../../config/axios'
import Loading from '../../components/common/Loading'
import Swal from 'sweetalert2'

const DeleteUser = () => {
  const [emails, setEmails] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/proyectos')
    }
  }, [navigate])

  useEffect(() => {
    const getUsers = async () => {
      try {
        const emails = await getAllUsersQuery('/usuarios')
        setEmails(emails)
        setLoading(false)
      } catch (error) {
        // TODO: CONTROLAR ESTE ERROR
        Swal.fire({
          icon: 'warning',
          title: 'Ha ocurrido un error',
        })
      }
    }
    getUsers()
  }, [navigate])

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Eliminar usuario</Typography>
          </div>
          <div>
            <Grid container justifyContent="center">
              <Grid item={true} xs={12} md={12} lg={12}>
                {loading ? (
                  <Loading />
                ) : emails.length > 0 ? (
                  <DeleteAccountData emails={emails} />
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography textAlign="center">
                      No hay usuarios a los que puedas eliminar
                    </Typography>
                    <Button
                      onClick={(e) => navigate('/inicio')}
                      variant="contained"
                      style={{ marginTop: 20, width: '50%' }}
                    >
                      Volver a inicio
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default DeleteUser
