import React from 'react'
import { Button } from '@mui/material'

const TableActions = ({
  editingIndex,
  index,
  row,
  handleEditClick,
  handleConfirmChanges,
  handleDeleteClick,
}) => (
  <>
    {editingIndex === index ? (
      <Button variant="contained" onClick={handleConfirmChanges}>
        Confirmar
      </Button>
    ) : (
      <>
        <Button variant="contained" onClick={() => handleEditClick(index, row)}>
          Editar
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: 10 }}
          onClick={() => handleDeleteClick(row.id)}
        >
          Borrar
        </Button>
      </>
    )}
  </>
)

export default TableActions
