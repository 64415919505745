import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import FormEditProfile from './FormEditProfile'
import { useNavigate } from 'react-router-dom'
import { checkToken } from '../../config/axios'
import Swal from 'sweetalert2'
const EditProfile = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await checkToken(
          '/authorization-api/check-token',
          localStorage.getItem('token')
        )
        if (response.status === 401) {
          Swal.fire({
            icon: 'warning',
            title: 'Debes volver a iniciar sesión',
            showConfirmButton: false,
            timer: 1500,
          })
          localStorage.clear()
          navigate('/iniciar-sesion')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal al obtener los proyectos!',
        })
      }
    }

    validateToken()
  }, [navigate])

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Editar perfil</Typography>
          </div>
          <div>
            <Grid container display="flex">
              <Grid item={true} xs={12} md={12} lg={12}>
                <FormEditProfile />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default EditProfile
