import React from 'react'

import { RenderSelectField } from './RenderSelectField'

export const RenderSelectFieldWithErrors = (props) => {
  const { label, register, error, options, value, onChange } = props
  return (
    <>
      <RenderSelectField
        label={label}
        register={register}
        error={error}
        options={options}
        value={value}
        onChange={onChange}
      />
      {error && <span style={{ color: 'red' }}>Este campo es requerido</span>}
    </>
  )
}
