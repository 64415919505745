import React from 'react'

import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Unstable_Grid2 as Grid,
  TextField,
} from '@mui/material'
import Swal from 'sweetalert2'
import { HomeContext } from '../../../context/HomeContext'
import { createProjectQuery } from '../../../config/axios'
import CustomTextField from '../../../components/CustomTextField/CustomTextField'

const CreateProjectForm = ({ types, categories, clients }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  let navigate = useNavigate()
  const [auth] = useContext(HomeContext)

  useEffect(() => {
    if (!localStorage.getItem('role')) {
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'No tienes los permisos para acceder a esta función',
      })
      navigate('/iniciar-sesion')
    }
  }, [auth.isAuth, auth.token, navigate])

  const onSubmit = async (data) => {
    data.project_or_activity = 'proyecto'

    data.project_client_id = Number(data.project_client_id)
    data.project_type_id = Number(data.project_type_id)
    data.project_category_id = Number(data.project_category_id)

    try {
      const response = await createProjectQuery(
        'proyectos-api/crear-nuevo-proyecto',
        data
      )
      Swal.fire({
        icon: 'success',
        title: '¡Proyecto creado!',
        text: response.msg,
      })
      reset()
    } catch (error) {
      const { msg } = error.response.data
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: msg,
      })
    }
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
        <CardHeader
          title="Crea un nuevo proyecto para tu organización"
          subheader="Debes ingresar los datos en los campos respectivos"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  register={register('project_name', { required: true })}
                  required
                  label="Ingrese el nombre del proyecto"
                  error={errors.project_name}
                />
                {errors.project_name && (
                  <span style={{ color: 'red', fontSize: '13px' }}>
                    Este campo es requerido
                  </span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  register={register('description')}
                  label="Ingrese una descripción del proyecto"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={`Seleccione el cliente`}
                  {...register('project_client_id', { required: true })}
                  select
                  SelectProps={{ native: true }}
                  error={Boolean(errors.project_client_id)}
                >
                  <option value="null"></option>
                  {clients.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.client_name}
                    </option>
                  ))}
                </TextField>
                {errors.project_client_id && (
                  <span style={{ color: 'red' }}>Este campo es requerido</span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={`Seleccione el tipo de proyecto`}
                  {...register('project_type_id', { required: true })}
                  select
                  SelectProps={{ native: true }}
                  error={Boolean(errors.project_type_id)}
                >
                  <option value="null"></option>
                  {types.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.type_name}
                    </option>
                  ))}
                </TextField>
                {errors.project_type_id && (
                  <span style={{ color: 'red' }}>Este campo es requerido</span>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={`Seleccione la categoría del proyecto`}
                  {...register('project_category_id', { required: true })}
                  select
                  SelectProps={{ native: true }}
                  error={Boolean(errors.project_category_id)}
                >
                  <option value="null"></option>
                  {categories.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.category_name}
                    </option>
                  ))}
                </TextField>
                {errors.project_category_id && (
                  <span style={{ color: 'red' }}>Este campo es requerido</span>
                )}
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button type="submit" variant="contained">
            Crear
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

export default CreateProjectForm
