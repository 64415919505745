import React from 'react'

import { useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { getAllProjectsQuery } from '../../config/axios'
import PostAddIcon from '@mui/icons-material/PostAdd'
import Loading from '../../components/common/Loading'
import CustomButton from './common/CustomButton'
import NotFound from '../../components/common/NotFound'
import Swal from 'sweetalert2'
import TableProjectsInfo from './components/TableProjectsInfo'

const Projects = () => {
  const navigate = useNavigate()
  const [projects, setProjects] = useState([])
  const [flag, setFlag] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])

  useEffect(() => {
    const getAllProjects = async () => {
      try {
        const response = await getAllProjectsQuery(
          `/proyectos-api/proyectos/${flag}`,
          localStorage.getItem('token')
        )

        if (response.status === 401) {
          Swal.fire({
            icon: 'warning',
            title: 'Debes volver a iniciar sesión',
            showConfirmButton: false,
            timer: 1500,
          })
          localStorage.clear()
          navigate('/iniciar-sesion')
        }
        setProjects(response.projects)
        setTotalPages(totalPages)
        setLoading(false)
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal al obtener los proyectos!',
        })
      }
    }

    getAllProjects()
  }, [flag, navigate, totalPages])

  const handleClick = () => setFlag((prevState) => !prevState)

  const redirectToPage = () => navigate('/proyectos/agregar-horas')

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <Container maxWidth="xl">
        {loading ? (
          <Loading />
        ) : projects.length === 0 ? (
          <NotFound
            redirect="/inicio"
            buttonText="Volver a inicio"
            text="No hay proyectos"
          />
        ) : (
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Typography variant="h4">Proyectos</Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CustomButton marginLeft="5px" onClick={handleClick}>
                  {flag ? 'Ver todos' : 'Ver vigentes'}
                </CustomButton>
                <CustomButton marginLeft="5px" onClick={redirectToPage}>
                  <PostAddIcon />
                </CustomButton>
              </div>
            </Stack>

            {loading ? (
              <Loading />
            ) : projects ? (
              <Grid
                container
                spacing={3}
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
              >
                <TableProjectsInfo projects={projects} />
              </Grid>
            ) : (
              <NotFound
                buttonText={'Agregar proyecto'}
                redirect={'/proyectos/crear-proyecto'}
                text="Aún no tienes registrado proyectos"
              />
            )}
          </Stack>
        )}
      </Container>
    </Box>
  )
}

export default Projects
