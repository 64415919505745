import { useState } from 'react'

export function useResetDedicationValues() {
  const [dedicatedAtProject, setDedicatedAtProject] = useState({
    user_id: '',
    project_id: '',
    week: '',
    dedicated: '',
  })

  const resetDedicationValues = () => {
    setDedicatedAtProject({
      user_id: '',
      project_id: '',
      week: '',
      dedicated: '',
    })
  }

  return [dedicatedAtProject, resetDedicationValues]
}
