import React from 'react'

import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import CreateProjectForm from './CreateProjectForm'
import { useEffect, useState } from 'react'
import {
  checkToken,
  getCategoriesProjectsQuery,
  getClientsQuery,
  getTypesProjectsQuery,
} from '../../../config/axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const CreateProject = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])

  const [data, setData] = useState({
    types: [],
    categories: [],
    clients: [],
  })

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await checkToken(
          '/check-token',
          localStorage.getItem('token')
        )
        if (response.status === 401) {
          Swal.fire({
            icon: 'warning',
            title: 'Debes volver a iniciar sesión',
            showConfirmButton: false,
            timer: 1500,
          })
          localStorage.clear()
          navigate('/iniciar-sesion')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal al obtener los proyectos!',
        })
      }
    }

    validateToken()
  }, [navigate])

  useEffect(() => {
    const fetchData = async () => {
      const typesPromise = getTypesProjectsQuery(
        '/types-api/all-projects-types',
        localStorage.getItem('token')
      )
      const categoriesPromise = getCategoriesProjectsQuery(
        'categories-api/all-projects-categories'
      )
      const clientsPromise = getClientsQuery('/clientes-api/all-clients')

      const [typesResponse, categoriesResponse, clientsResponse] =
        await Promise.all([typesPromise, categoriesPromise, clientsPromise])

      setData({
        types: typesResponse?.types || [],
        categories: categoriesResponse?.categories || [],
        clients: clientsResponse?.clients || [],
      })
    }

    fetchData()
  }, [])

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Crear proyecto</Typography>
          {/* {canDisplayForm && ( */}
          <Grid container display="flex">
            <Grid item={true}>
              <CreateProjectForm {...data} />
            </Grid>
          </Grid>
          {/* )} */}
        </Stack>
      </Container>
    </Box>
  )
}

export default CreateProject
