import React from 'react'

import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        width: '100%',
      }}
    >
      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
        <CircularProgress style={{ color: '##01334C' }} />
        <CircularProgress style={{ color: '#126C8A' }} />
        <CircularProgress style={{ color: '#1D81A0' }} />
      </Stack>
    </div>
  )
}

export default Loading
