import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'

const TableProjectsInfo = ({ projects }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead
          sx={{
            background: '#4A3E57',
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                color: 'white',
              }}
            >
              Nombre del Proyecto
            </TableCell>
            <TableCell
              sx={{
                color: 'white',
              }}
            >
              Nombre del Cliente
            </TableCell>
            <TableCell
              sx={{
                color: 'white',
              }}
            >
              Categoría del Proyecto
            </TableCell>
            <TableCell
              sx={{
                color: 'white',
              }}
            >
              Tipo de Proyecto
            </TableCell>
            <TableCell
              sx={{
                color: 'white',
              }}
            >
              Estado del Proyecto
            </TableCell>
            <TableCell
              sx={{
                color: 'white',
              }}
            >
              Fecha de Creación
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((project) => (
              <TableRow key={project.id}>
                <TableCell component="th" scope="row">
                  {project.project_name}
                </TableCell>
                <TableCell>{project.client.clientName}</TableCell>
                <TableCell>{project.category.categoryName}</TableCell>
                <TableCell>{project.type.typeName}</TableCell>
                <TableCell>{project.project_status}</TableCell>
                <TableCell>
                  {new Date(project.createdAt).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={projects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Filas por página:"
      />
    </TableContainer>
  )
}

export default TableProjectsInfo
