import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

export const DataSalariesTable = ({ data }) => (
  <TableContainer component={Paper} sx={{ mt: 3 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>user_id</TableCell>
          <TableCell>salarie</TableCell>
          <TableCell>detail</TableCell>
          <TableCell>date</TableCell>
          <TableCell>created_at</TableCell>
          <TableCell>updated_at</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.user_id}</TableCell>
            <TableCell>{row.salarie}</TableCell>
            <TableCell>{row.detail}</TableCell>
            <TableCell>{row.date}</TableCell>
            <TableCell>{row.created_at}</TableCell>
            <TableCell>{row.updated_at}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)
