import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'

import { getProjectById } from '../../config/axios'
import { ProjectDetailsData } from './ProjectDetailsData'
import Loading from '../../sections/loading/Loading'

const ProjectDetails = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])

  const { slug, id } = useParams()
  const [project, setProject] = useState([null]) // Use null as initial state

  useEffect(() => {
    // Fetch the project data when the component is mounted or when id or slug change
    const fetchProject = async () => {
      const response = await getProjectById('/proyectos-api', slug, id)
      setProject(response[0])
    }

    fetchProject()
  }, [id, slug])

  // Show loading screen while the project data is being fetched
  if (!project) {
    return <Loading />
  }

  // Destructuring properties from the project object

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container>
        <Stack spacing={3}>
          <Typography variant="h4" textAlign="center">
            {project.project_name}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={12}>
              <ProjectDetailsData project={project} />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  )
}

export default ProjectDetails
