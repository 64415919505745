import React from 'react'

import { Button } from '@mui/material'

/**
 * Botón personalizado para la barra de herramientas de proyectos.
 * @param {Object} props Propiedades del componente.
 * @returns Componente Button.
 */
const CustomButton = ({
  children,
  marginLeft = 0,
  variant = 'contained',
  onClick,
}) => (
  <Button style={{ marginLeft }} variant={variant} onClick={onClick}>
    {children}
  </Button>
)

export default CustomButton
