import React from 'react'

import { useNavigate } from 'react-router-dom'
import { HolidaysForm } from '../../components/HolidaysForm/HolidaysForm'
import { Box, Container, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'

export const HolidaysPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Registrar Días Libres</Typography>
          <HolidaysForm />
        </Stack>
      </Container>
    </Box>
  )
}
