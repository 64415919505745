import React from 'react'

import { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import { RenderSelectField } from './commons/RenderSelectField'
import dayjs from 'dayjs'
import { RenderSelectFieldWithErrors } from './commons/RenderSelectFieldWithErrors '
import { isFormValid } from './commons/isFormValid'
import { createOutlayQuery } from '../../config/axios'
import Swal from 'sweetalert2'
import { formatedNumber } from '../../functions/formatedNumber'
import { DateFieldComponent } from './commons/DateFieldComponent'
import CustomTextField from '../../components/CustomTextField/CustomTextField'

const OutlayForm = ({ types, temporalities, projects, categories }) => {
  const [outlayData, setOutlayData] = useState({
    typeId: '',
    projectOrCategoryId: '',
    amount: '',
    detail: '',
    date: dayjs().format('YYYY-MM-DD'),
    temporalityId: '',
    isProject: true,
  })

  const {
    register,
    formState: { errors },
    reset,
  } = useForm()

  const handleChange = (field, value) => {
    if (field === 'amount') {
      value = value.replace(/\D/g, '')
      value = formatedNumber(value)
    }
    setOutlayData((prevData) => ({
      ...prevData,
      [field]: value,
    }))
  }

  useEffect(() => {
    handleChange()
  }, [outlayData.typeId])

  const optionsToRender = outlayData.typeId === '1' ? projects : categories

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const { msg } = await createOutlayQuery(
        '/outlay-api/create-outlay',
        outlayData
      )

      Swal.fire({
        icon: 'success',
        title: '¡Registro exitoso!',
        msg: msg,
      })

      reset()
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: error.message,
      })
    }
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
        <CardHeader
          title="Ingresar nuevo desembolso"
          subheader="Debes ingresar los datos en los campos respectivos"
        />
        <CardContent sx={{ pt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <RenderSelectFieldWithErrors
                label={`Seleccionar tipo de desembolso`}
                error={errors.outlayType}
                options={types}
                value={outlayData.typeId}
                onChange={(e) => handleChange('typeId', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <RenderSelectFieldWithErrors
                label={`Categoría o proyecto`}
                {...register('projectOrCategoryId', {
                  required: true,
                  setValueAs: (value) =>
                    handleChange('projectOrCategoryId', value),
                })}
                error={errors.outlayType}
                options={optionsToRender}
                value={outlayData.projectOrCategoryId}
                onChange={(e) =>
                  handleChange('projectOrCategoryId', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <CustomTextField
                register={register('amount', {
                  required: true,
                  setValueAs: (value) => handleChange('amount', value),
                })}
                required
                label="Ingrese el monto"
                value={outlayData.amount}
                type="text"
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <CustomTextField
                register={register('detail', {
                  required: true,
                  setValueAs: (value) => handleChange('detail', value),
                })}
                required
                label="Ingrese el detalle"
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <DateFieldComponent
                label="Ingrese fecha"
                value={outlayData.date}
                onChange={(value) => handleChange('date', value)}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <RenderSelectField
                label="Temporalidad"
                register={register('temporalityId', {
                  required: true,
                  setValueAs: (value) => handleChange('temporalityId', value),
                })}
                options={temporalities}
                value={outlayData.temporalityId}
                onChange={(e) => handleChange('temporalityId', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="end" marginTop={3}>
            <Button
              disabled={!isFormValid(outlayData)}
              type="submit"
              variant="contained"
            >
              Registrar
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}

export default OutlayForm
