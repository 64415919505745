  import React from 'react'

  import Accordion from '@mui/material/Accordion'
  import AccordionDetails from '@mui/material/AccordionDetails'
  import AccordionSummary from '@mui/material/AccordionSummary'
  import Typography from '@mui/material/Typography'
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
  import { useEffect } from 'react'
  import { getAllUsersDedicationByMonth } from '../../../config/axios'
  import Swal from 'sweetalert2'
  import { useState } from 'react'
  import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
  import { formatDate } from '../../../functions/formateDate'
  import ConsolidationHoursAccordionSkeleton from './ConsolidationHoursAccordionSkeleton'

  const ConsolidationHoursAccordion = () => {
    const [expanded, setExpanded] = useState(false)
    const [dedications, setDedications] = useState([])
    const [loading, setLoading] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    }

    useEffect(() => {
      const updateDedication = async () => {
        try {
          setLoading(true)
          const data = await getAllUsersDedicationByMonth(
            '/dedicacion-api/dedicaciones-por-mes'  
          )

          setDedications(data.dedications)
          setLoading(false)
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Ha ocurrido un error',
          })
        }
      }

      updateDedication()
    }, [])

    return (
      <>
        {loading ? (
          <ConsolidationHoursAccordionSkeleton />
        ) : (
          dedications.map((userDedication, idx) => (
            <Accordion
              key={idx}
              elevation={3}
              expanded={expanded === `panel${idx}`}
              onChange={handleChange(`panel${idx}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${idx}bh-content`}
                id={`panel${idx}bh-header`}
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {userDedication.user}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Proyecto</TableCell>
                      <TableCell align="center">Semana</TableCell>
                      <TableCell align="center">Porcentaje acumulado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userDedication.dedications.map((dedication) => (
                      <TableRow key={dedication.id}>
                        <TableCell>
                          {dedication.client_name} - {dedication.project_name}
                        </TableCell>
                        <TableCell align="center">
                          {' '}
                          {formatDate(dedication.week)}
                        </TableCell>
                        <TableCell align="center">
                          {dedication.dedicated * 100} %
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </>
    )
  }

  export default ConsolidationHoursAccordion
