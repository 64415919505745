import Swal from 'sweetalert2'

export const ShowMessageAlert = (title, icon, text, timer) => {
  Swal.fire({
    title: title,
    icon: icon,
    text: text,
    timer: timer ? timer : null,
    showConfirmButton: timer ? false : true,
  })
}