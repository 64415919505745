import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Grid,
  InputAdornment,
  Typography,
  Tabs,
  Tab,
} from '@mui/material'
import { convertDateFormat } from '../../../utils/convertDateFormat'
import {
  addDedicationAtProjectQuery,
  getDedicationsByUserIdQuery,
  updateConsolidationsQuery,
} from '../../../config/axios'
import useWeeksOfMonth from '../../../hooks/useWeekOfMonth'
import ConsolidationHoursTable from '../../dedications/ConsolidationHoursTable'
import TabPanel from '../common/TabPanel'
import { useResetDedicationValues } from '../../../hooks/useResetDedicationValues'
import { ProjectSelectorData } from '../ProjectSelectorData'
import { a11yProps } from '../../../utils/allyProps'
import { filterAndProcessDedications } from '../../../utils/filterAndProcessDedications'
import { ShowMessageAlert } from '../../../components/common/ShowMessageAlert'
import Swal from 'sweetalert2'
import ConsolidationHoursAccordion from '../common/ConsolidationHoursAccordion'
import { createData } from './functions/createData'

const FormProjectDedication = ({ user, setUser, projects }) => {
  const [selectedProject, setSelectedProject] = useState('')
  const [selectedWeek, setSelectedWeek] = useState('')
  const [selectedDedication, setSelectedDedication] = useState(0)
  const [dedicatedAtProject, resetDedicationValues] = useResetDedicationValues()
  const [, setTableData] = useState([])
  const [dedication, setDedication] = useState([])
  const [dedicationChanged, setDedicationChanged] = useState(false)
  const [value, setValue] = useState(0)
  const [consolidatedDedications, setConsolidatedDedications] = useState([])

  const weeks = useWeeksOfMonth()

  useEffect(() => {
    const updateDedication = async () => {
      try {
        const { dedications } = await getDedicationsByUserIdQuery(
          '/dedicacion-api/dedicaciones-legado',
          user.id
        )

        const processedDedications = filterAndProcessDedications(dedications)

        setDedication(processedDedications)
        setConsolidatedDedications(
          dedications.filter((dedication) => dedication.consolidation === true)
        )
        setDedicationChanged(false)
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error',
        })
      }
    }

    updateDedication()
  }, [user.id, dedicationChanged])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }))
  }

  const handleProjectChange = (event) => {
    const selectedProject = event.target.value
    setSelectedProject(selectedProject)
  }

  const handleDateChange = (event) => {
    const selectedWeek = event.target.value
    setSelectedWeek(selectedWeek)
  }

  const handleCheckDedication = (event) => {
    const inputValue = event.target.value
    if (inputValue >= 0) {
      setSelectedDedication(inputValue)
    }
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const dedicationData = dedication.map((item) => ({
      ...item,
      consolidation: 1,
      dedicated: item.dedicated / 100,
    }))

    try {
      const { msg } = await updateConsolidationsQuery(
        '/dedicacion-api/actualizar-consolidacion',
        dedicationData
      )
      updateTable(
        createData(user.name, selectedProject, selectedWeek, selectedDedication)
      )

      setDedication([])

      ShowMessageAlert('¡Todo ha salido bien!', 'success', msg)

      resetDedicationValues()
    } catch (error) {
      const { msg } = error.response.data
      ShowMessageAlert('Ha ocurrido un error', 'error', msg)
    }
  }

  const handleSaveSubmit = async (event) => {
    event.preventDefault()
    const dedicatedAtProjectData = {
      ...dedicatedAtProject,
      user_id: user.id,
      project_id: selectedProject,
      week: convertDateFormat(selectedWeek),
      dedicated: selectedDedication,
      consolidation: 0,
    }

    try {
      const { msg } = await addDedicationAtProjectQuery(
        '/dedicacion-api/ingresar-dedicacion',
        dedicatedAtProjectData
      )
      updateTable(
        createData(user.name, selectedProject, selectedWeek, selectedDedication)
      )
      const { dedications } = await getDedicationsByUserIdQuery(
        '/dedicacion-api/dedicaciones-legado',
        user.id
      )

      const processedDedications = filterAndProcessDedications(dedications)

      setDedication(processedDedications)
      setConsolidatedDedications(
        dedications.filter((dedication) => dedication.consolidation === 1)
      )
      setDedicationChanged(false)

      ShowMessageAlert('¡Dedicación ingresada!', 'success', msg, '1500')

      resetDedicationValues()
    } catch (error) {
      const { msg } = error.response.data
      ShowMessageAlert('Ha ocurrido un error', 'error', msg)
    }
  }

  const updateTable = (newRow) => {
    setTableData((prevData) => [...prevData, newRow])
  }

  const totalDedication = dedication.reduce(
    (sum, row) => sum + row.dedicated,
    0
  )

  return (
    <form autoComplete="off" noValidate>
      <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
        <CardHeader subheader="Acá debes introducir la cantidad de dedicación semanal a un proyecto en particular" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  helperText="Nombre del trabajador"
                  name="name"
                  onChange={handleInputChange}
                  value={user.name}
                  disabled
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <ProjectSelectorData
                  data={projects}
                  selectedProject={selectedProject}
                  handleChange={handleProjectChange}
                />
              </Grid>

              <Grid item xs={12}>
                <ProjectSelectorData
                  type="week"
                  data={weeks}
                  selectedProject={selectedWeek}
                  handleChange={handleDateChange}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Porcentaje de dedicación"
                  name="dedication"
                  onChange={handleCheckDedication}
                  value={selectedDedication}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    inputProps: {
                      min: 1,
                      max: 100,
                      type: 'number',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label="Registrar horas" {...a11yProps(0)} />
              <Tab label="Ver historial de registros" {...a11yProps(1)} />
              {localStorage.getItem('role') === 'directoraejecutiva' && (
                <Tab label="Ver equipo de trabajo" {...a11yProps(2)} />
              )}
            </Tabs>
          </Box>
          <div>
            <TabPanel value={value} index={0}>
              {dedication.length === 0 ? (
                <Typography variant="h4" textAlign="center">
                  Aún no has registrado datos
                </Typography>
              ) : (
                <ConsolidationHoursTable
                  projects={projects}
                  weeks={weeks}
                  dedication={dedication}
                  setDedication={setDedication}
                  totalDedication={totalDedication || 0}
                  dedicationChanged={dedicationChanged}
                  setDedicationChanged={setDedicationChanged}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ConsolidationHoursTable
                projects={projects}
                weeks={weeks}
                dedication={consolidatedDedications}
                view="true"
                nameTab="Ver historial de registros"
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ConsolidationHoursAccordion />
            </TabPanel>
          </div>
        </Box>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            display: value === 1 ? 'none' : 'flex',
          }}
        >
          <Button
            onClick={handleSaveSubmit}
            disabled={
              !selectedProject || selectedProject.value === 'null'
                ? true
                : false
            }
            variant="contained"
          >
            Guardar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!(totalDedication === 100)}
            variant="contained"
          >
            Terminar registro
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

export default FormProjectDedication
