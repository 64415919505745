import * as React from 'react'
import Box from '@mui/material/Box'

const BoxInfo = ({title, info}) => {
  return (
    <div style={{ width: '100%' }}>
      <h3> {title} </h3>
      <Box
        component="div"
        sx={{
          whiteSpace: 'normal',
          p: 1,

          color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          fontSize: '1rem',
          fontWeight: '500',
          lineHeight: '2',
        }}
      >
        {info}
      </Box>
    </div>
  )
}

export default BoxInfo