import axios from 'axios'
import { removeDots } from '../functions/removeDots'

const dashAxios = axios.create({
  baseURL:
    'https://agenciapolux-backend-production.up.railway.app/agencia-polux/api/v1',
})

/**
 * Obtiene los datos del dashboard de un usuario en la base de datos.
 * @param {string} url - La URL de la API para obtener el dashboard.
 * @param {string} name - El rol del usuario.
 * @returns {object} - La respuesta de la API que contiene los datos del dashboard.
 */

export const getDashboardQuery = async (url, token, name) => {
  const response = await dashAxios.get(`${url}/${name}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}

export const createUserAccount = async (url, user, token) => {
  const newUser = await dashAxios.post(url, user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return newUser
}

export const queryLogin = async (url, user) => {
  const response = await dashAxios.post(url, user)
  return response
}

// USERS QUERIES
/**
 * Realiza una solicitud GET a una URL específica utilizando la biblioteca dashAxios
 * y devuelve los correos electrónicos de los usuarios obtenidos en la respuesta.
 *
 * @param {string} url - La URL a la que se realizará la solicitud GET.
 * @returns {Promise<string[]>} - Una promesa que se resuelve en los correos electrónicos de los usuarios obtenidos.
 */
export const getAllUsersQuery = async (url) => {
  try {
    const response = await dashAxios.get(url)

    return response.data.emails
  } catch (error) {
    // Manejo de errores en caso de que ocurra un problema durante la solicitud
    console.error('Error al obtener los usuarios:', error)
    throw error
  }
}

/**
 * Realiza una solicitud GET a una URL específica generada a partir de la URL base, el slug y el ID proporcionados, y devuelve los datos obtenidos en la respuesta.
 *
 * @param {string} url - La URL base a la que se concatenará el slug y el ID.
 * @param {string} slug - El slug que se agregará a la URL.
 * @param {string} id - El ID que se agregará a la URL.
 * @returns {Promise<any>} - Una promesa que se resuelve en los datos obtenidos en la respuesta.
 */
export const getProjectById = async (url, slug, id) => {
  try {
    const response = await dashAxios.get(`${url}/${slug}/${id}`)

    return response.data.project
  } catch (error) {
    console.error('Error al obtener los datos del proyecto:', error)
    throw error
  }
}

/**
 * Realiza una solicitud GET a una URL especificada y devuelve los datos de respuesta obtenidos.
 * @param {string} url - La URL a la que se realizará la solicitud GET.
 * @returns {Promise<any>} - Una promesa que se resuelve en los datos de respuesta de la solicitud.
 */
export const getAllProjectsQuery = async (url, token) => {
  try {
    const response = await dashAxios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log(response)
    return response.data
  } catch (error) {
    return error.response
  }
}

export const getAllProjectsAndActivitiesQuery = async (url, token) => {
  try {
    const response = await dashAxios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response
  }
}

/**
 * Realiza una solicitud GET a una URL especificada y devuelve los datos de respuesta obtenidos.
 * @param {string} url - La URL a la que se realizará la solicitud GET.
 * @returns {Promise<any>} - Una promesa que se resuelve en los datos de respuesta de la solicitud.
 */
export const checkToken = async (url, token) => {
  try {
    const response = await dashAxios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response
  }
}

/**
 * Función para obtener roles y días laborables de la API.
 *
 * @param {string} url - La URL del endpoint de la API a la que queremos hacer la solicitud GET.
 * @returns {Promise<Object>} - Retorna una promesa que resuelve con los datos de la respuesta de la API.
 *
 * @throws {Error} - Lanza un error si la solicitud a la API falla.
 */
export const getRolesAndWorkday = async (url, token) => {
  const response = await dashAxios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}

/**
 * Actualiza una dedicación en la base de datos.
 * @param {string} url - La URL de la API para actualizar la dedicación.
 * @param {object} updatedDedication - Los datos actualizados de la dedicación.
 * @returns {object} - La respuesta de la API.
 */
export const updateDedicationQuery = async (url, updatedDedication) => {
  updatedDedication.dedicated = updatedDedication.dedicated / 100
  const response = await dashAxios.patch(url, updatedDedication)
  return response
}

/**
 * Actualiza las consolidaciones en la base de datos.
 * @param {string} url - La URL de la API para actualizar las consolidaciones.
 * @param {object} data - Los datos de consolidación a actualizar.
 * @returns {object} - La respuesta de la API.
 */
export const updateConsolidationsQuery = async (url, data) => {
  const response = await dashAxios.patch(url, data)
  return response.data
}

/**
 * Actualiza las consolidaciones en la base de datos.
 * @param {string} url - La URL de la API para actualizar las consolidaciones.
 * @param {object} data - Los datos de consolidación a actualizar.
 * @returns {object} - La respuesta de la API.
 */
export const getAllUsersDedicationByMonth = async (url, data) => {
  const response = await dashAxios.get(url)
  return response.data
}

/**
 * Obtiene las dedicaciones de un usuario por su ID.
 * @param {string} url - La URL de la API para obtener las dedicaciones.
 * @param {string} userId - El ID del usuario.
 * @returns {object} - La respuesta de la API que contiene las dedicaciones del usuario.
 */
export const getDedicationsByUserIdQuery = async (url, userId) => {
  const response = await dashAxios.get(`${url}/${userId}`)
  return response.data
}

/**
 * Edita el perfil de un usuario en la base de datos.
 * @param {string} url - La URL de la API para editar el perfil.
 * @param {object} user - Los datos actualizados del usuario.
 * @returns {object} - La respuesta de la API.
 */
export const editProfileQuery = async (url, user, token) => {
  console.log(user)
  const response = await dashAxios.put(url, user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}

/**
 * Crea o edita un panel de control en la base de datos.
 * @param {string} url - La URL de la API para crear o editar el panel de control.
 * @param {object} dashboard - Los datos del panel de control.
 * @param {string} token - El token de autenticación.
 * @returns {object} - La respuesta de la API.
 */
export const createOrEditDashboardQuery = async (url, dashboard, token) => {
  const response = await dashAxios.post(url, dashboard, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}

/**
 * Agrega una dedicación a un proyecto en la base de datos.
 * @param {string} url - La URL de la API para agregar la dedicación.
 * @param {object} data - Los datos de la dedicación.
 * @returns {object} - La respuesta de la API que contiene los datos de la dedicación agregada.
 */
export const addDedicationAtProjectQuery = async (url, data) => {
  data.dedicated = data.dedicated / 100
  const response = await dashAxios.post(url, data)
  return response.data
}

/**
 * Elimina una cuenta de usuario de la base de datos.
 * @param {string} url - La URL de la API para eliminar la cuenta de usuario.
 * @param {string} user - El nombre de usuario.
 * @param {string} userRole - El rol del usuario.
 * @param {string} emailToDelete - El correo electrónico de la cuenta a eliminar.
 * @param {string} token - El token de autenticación.
 * @returns {object} - La respuesta de la API.
 */
export const deleteAccountQuery = async (
  url,
  user,
  userRole,
  emailToDelete,
  token
) => {
  const data = await dashAxios.delete(
    `${url}/${user}/${userRole}/${emailToDelete}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return data
}

/**
 * Obtiene los tipos de proyectos desde la base de datos.
 * @param {string} url - La URL de la API para obtener los tipos de proyectos.
 * @returns {object} - La respuesta de la API que contiene los tipos de proyectos.
 */
export const getTypesProjectsQuery = async (url, token) => {
  const response = await dashAxios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}

/**
 * Obtiene las categorías de proyectos desde la base de datos.
 * @param {string} url - La URL de la API para obtener las categorías de proyectos.
 * @returns {object} - La respuesta de la API que contiene las categorías de proyectos.
 */
export const getCategoriesProjectsQuery = async (url) => {
  const response = await dashAxios.get(url)
  return response.data
}

/**
 * Obtiene los clientes desde la base de datos.
 * @param {string} url - La URL de la API para obtener los clientes.
 * @returns {object} - La respuesta de la API que contiene los clientes.
 */
export const getClientsQuery = async (url) => {
  const response = await dashAxios.get(url)
  return response.data
}

/**
 * Crea un nuevo proyecto en la base de datos.
 * @param {string} url - La URL de la API para crear el proyecto.
 * @param {object} data - Los datos del proyecto.
 * @returns {object} - La respuesta de la API que contiene los datos del proyecto creado.
 */
export const createProjectQuery = async (url, data) => {
  console.log(data)
  const response = await dashAxios.post(url, data)
  return response.data
}

/**
 * Crea un nuevo cliente en la base de datos.
 * @param {string} url - La URL de la API para crear el cliente.
 * @param {object} data - Los datos del cliente.
 * @returns {object} - La respuesta de la API que contiene los datos del cliente creado.
 */
export const createClientQuery = async (url, data) => {
  const response = await dashAxios.post(url, data)
  return response.data
}

export const getProjectsQuery = async (url, token) => {
  const response = await dashAxios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data
}

export const getUsersQuery = async (url, token) => {
  const response = await dashAxios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data
}

export const getOutlayCategoriesQuery = async (url, token) => {
  const response = await dashAxios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data
}

/**
 * Elimina una cuenta de usuario de la base de datos.
 * @param {string} url - La URL de la API para eliminar la cuenta de usuario.
 * @param {string} user - El nombre de usuario.
 * @param {string} userRole - El rol del usuario.
 * @param {string} emailToDelete - El correo electrónico de la cuenta a eliminar.
 * @param {string} token - El token de autenticación.
 * @returns {object} - La respuesta de la API.
 */
export const deleteProjectQuery = async (url, auth, project, token) => {
  const response = await dashAxios.patch(
    `${url}/${auth.email}`,
    {
      auth,
      project,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return response
}

export const deleteDedicationQuery = async (url, id) => {
  const response = await dashAxios.delete(`${url}/${id}`)
  return response
}

export const getDataInfo = async (url, auth) => {
  const response = await dashAxios.get(`${url}/${auth.id}/${auth.email}`, {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  })
  return response
}

export const createOutlayQuery = async (url, data) => {
  const outlay = removeDots(data)
  const response = await dashAxios.post(url, outlay)
  return response.data
}

export const createHoliday = async (url, data, token) => {
  const response = await dashAxios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}

export const createIncomeQuery = async (url, data, token) => {
  const response = await dashAxios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  console.log(response)

  return response
}

export const createSalarieQuery = async (url, data, token) => {
  const response = await dashAxios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  console.log(response)

  return response
}

export default dashAxios
