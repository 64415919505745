import React from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

export const DataIncomeTable = ({ data }) => (
  <TableContainer component={Paper} sx={{ mt: 3 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>detail</TableCell>
          <TableCell>amount</TableCell>
          <TableCell>uf</TableCell>
          <TableCell>date</TableCell>

          <TableCell>project_id</TableCell>
          <TableCell>temporalities_id</TableCell>
          <TableCell>month</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.detail}</TableCell>
            <TableCell>{row.amount}</TableCell>
            <TableCell>{row.uf}</TableCell>
            <TableCell>{row.date}</TableCell>
            <TableCell>{row.project_id}</TableCell>
            <TableCell>{row.temporalities_id}</TableCell>
            <TableCell>{row.month}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)
