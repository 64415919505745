import React from 'react'
import { Box, Container, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  checkToken,
  getDataInfo,
  getOutlayCategoriesQuery,
  getProjectsQuery,
} from '../../config/axios'
import { HomeContext } from '../../context/HomeContext'
import OutlayForm from './OutlayForm'

const Outlay = () => {
  const navigate = useNavigate()
  const [auth] = useContext(HomeContext)
  const [temporalities, setTemporalities] = useState([])
  const [outlayTypes, setOutlayTypes] = useState([])
  const [projects, setProjects] = useState([])
  const [outlayCategories, setOutlayCategories] = useState([])
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])

  useEffect(() => {
    const getOutlayData = async () => {
      try {
        const { data } = await getDataInfo(
          '/outlay-api/get-all-outlay-data',
          auth
        )
        setOutlayTypes(data.outlayTypes)
        setTemporalities(data.outlayTemporalities)
      } catch (error) {
        console.log(error)
      }
    }

    getOutlayData()
  }, [auth])

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await checkToken(
          '/check-token',
          localStorage.getItem('token')
        )
        if (response.status === 401) {
          Swal.fire({
            icon: 'warning',
            title: 'Debes volver a iniciar sesión',
            showConfirmButton: false,
            timer: 1500,
          })
          localStorage.clear()
          navigate('/iniciar-sesion')
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal al obtener los proyectos!',
        })
      }
    }

    validateToken()
  }, [navigate])

  useEffect(() => {
    const getProjects = async () => {
      try {
        const { projects } = await getProjectsQuery(
          '/proyectos-api/proyectos/true',
          localStorage.getItem('token')
        )
        setProjects(projects)
      } catch (error) {
        console.log(error)
      }
    }

    getProjects()
  }, [auth])

  useEffect(() => {
    const getOutlayCategories = async () => {
      try {
        const { outlayCategories } = await getOutlayCategoriesQuery(
          '/outlay-api/get-all-outlay-categories',
          localStorage.getItem('token')
        )
        setOutlayCategories(outlayCategories)
      } catch (error) {
        console.log(error)
      }
    }

    getOutlayCategories()
  }, [])

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Registrar desembolsos</Typography>
          <OutlayForm
            types={outlayTypes}
            temporalities={temporalities}
            projects={projects}
            categories={outlayCategories}
          />
        </Stack>
      </Container>
    </Box>
  )
}

export default Outlay
