import React from 'react'

import { useContext, useEffect, useState } from 'react'
import {
  Box,
  Unstable_Grid2 as Grid,
  Typography,
  Stack,
  Container,
} from '@mui/material'

import FormProjectDedication from './FormProjectDedication'
import { getAllProjectsAndActivitiesQuery } from '../../../config/axios'
import { HomeContext } from '../../../context/HomeContext'
import NotFound from '../../../components/common/NotFound'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../components/common/Loading'
import Swal from 'sweetalert2'

const AddDedication = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate])

  const [auth] = useContext(HomeContext)

  const user = auth

  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await getAllProjectsAndActivitiesQuery(
          '/proyectos-api/proyectos-y-actividades',
          localStorage.getItem('token')
        )
        if (response.status === 401) {
          Swal.fire({
            icon: 'warning',
            title: 'Debes volver a iniciar sesión',
            showConfirmButton: false,
            timer: 1500,
          })
          localStorage.clear()
          navigate('/iniciar-sesion')
        }

        setProjects(response.projects)
        setLoading(false)
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal al obtener los proyectos!',
        })
      }
    }

    getProjects()
  }, [navigate])

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Stack spacing={3}>
            <Typography variant="h4">Agregar dedicación a proyecto</Typography>
            <Grid container>
              <Grid item xs={12} lg={12}>
                {projects.length === 0 ? (
                  <NotFound
                    redirect="/proyectos/crear-proyecto"
                    buttonText="Agregar proyecto"
                    text="No hay proyectos creados o vigentes"
                  />
                ) : (
                  <FormProjectDedication user={user} projects={projects} />
                )}
              </Grid>
            </Grid>
          </Stack>
        </Container>
      )}
    </Box>
  )
}

export default AddDedication
