/**
 * Crea un objeto de datos con los parámetros proporcionados.
 * @param {string} name - El nombre del trabajador.
 * @param {string} project - El proyecto seleccionado.
 * @param {string} week - La semana seleccionada.
 * @param {number} dedication - La cantidad de dedicación.
 * @returns {object} - Un objeto que contiene los datos proporcionados.
 */
export function createData(name, project, week, dedication) {
  return { name, project, week, dedication }
}
