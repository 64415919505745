import React, { useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Home from './pages/home/Home'
import EditProfile from './pages/edit-profile/EditProfile'
import { HomeContext, HomeProvider } from './context/HomeContext'
import { useScreenDimensions } from './hooks/useScreenDimensions'
import Login from './pages/Login'
import Projects from './pages/projects/Projects'
import CreateUser from './pages/create-user/CreateUser'
import DeleteUser from './pages/delete-user/DeleteUser'
import ProjectDetails from './pages/projects/ProjectDetails'
import MainDrawer from './pages/sidebar/MainDrawer'
import PermanentDrawer from './pages/sidebar/PermanentDrawer'
import AddDedication from './pages/projects/project-dedication/AddDedication'
import CreateProject from './pages/projects/create-project/CreateProject'
import CreateClient from './pages/create-client/CreateClient'
import ProjectDelete from './pages/delete-project/ProjectDelete'
import Outlay from './pages/outlay/Outlay'
import IncomePage from './pages/income'
import MonitorPage from './pages/monitor/MonitorPage'
import SalariesPage from './pages/salaries/SalariesPage'
import { HolidaysPage } from './pages/holidays/HolidaysPage'

const AppRouting = () => {
  const [width] = useScreenDimensions()
  const location = useLocation()
  const [auth, setAuth] = useContext(HomeContext)

  return (
    <>
      <HomeProvider value={[auth, setAuth]}>
        <Routes>
          <Route path="/iniciar-sesion" element={<Login />} />
          <Route
            element={
              width < 780 ? (
                <MainDrawer />
              ) : (
                <PermanentDrawer currentPath={location.pathname} />
              )
            }
          >
            <Route exact path="/inicio" element={<Home />} />
            <Route exact path="/proyectos" element={<Projects />} />
            <Route
              exact
              path="/proyectos/agregar-horas"
              element={<AddDedication />}
            />
            <Route
              exact
              path="/proyectos/crear-proyecto"
              element={<CreateProject />}
            />
            <Route
              exact
              path="/proyectos/terminar-proyecto"
              element={<ProjectDelete />}
            />
            <Route exact path="/monitoreo" element={<MonitorPage />} />
            <Route
              exact
              path="/proyectos/:slug/:id"
              element={<ProjectDetails />}
            />
            <Route path="/crear-cliente" element={<CreateClient />} />
            <Route path="/crear-usuario" element={<CreateUser />} />
            <Route path="/eliminar-usuario" element={<DeleteUser />} />
            <Route path="/editar-perfil" element={<EditProfile />} />
            <Route path="/registrar-desembolsos" element={<Outlay />} />
            <Route path="/registrar-ingresos" element={<IncomePage />} />
            <Route path="/registrar-sueldos" element={<SalariesPage />} />
            <Route path="/dia-libre" element={<HolidaysPage />} />
            {/* <Route path='/dashboard' element={<DashboardAdmin />} /> */}
          </Route>
          <Route path="*" element={<Navigate to="/iniciar-sesion" replace />} />
        </Routes>
      </HomeProvider>
    </>
  )
}

export default AppRouting
