import React from 'react'
import { TableRow, TableCell, TextField } from '@mui/material'
import { ProjectSelectorData } from '../../projects/ProjectSelectorData'
import TableActions from './TableActions'

const TableRowItem = ({
  row,
  index,
  editingIndex,
  tempDedication,
  weeks,
  dedication,
  handleCellChange,
  handleSelectorChange,
  handleEditClick,
  handleConfirmChanges,
  handleDeleteClick,
  nameTab,
}) => (
  <TableRow
    key={row.id}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  >
    <TableCell component="th" scope="row">
      {row.user_name}
    </TableCell>
    <TableCell align="center">
      {row.client_name} - {row.project_name}
    </TableCell>
    <TableCell align="center">
      {editingIndex === index ? (
        <ProjectSelectorData
          weeks={weeks}
          type="week"
          data={dedication}
          selectedProject={(tempDedication || {}).week}
          handleChange={handleSelectorChange}
        />
      ) : (
        row.week
      )}
    </TableCell>
    <TableCell align="center">
      {editingIndex === index ? (
        <TextField
          value={tempDedication.dedicated}
          onChange={(event) => handleCellChange(event, 'dedicated')}
        />
      ) : (
        `${row.dedicated} %`
      )}
    </TableCell>
    {nameTab !== 'Ver historial de registros' && (
      <TableCell align="right">
        <TableActions
          editingIndex={editingIndex}
          index={index}
          row={row}
          handleEditClick={handleEditClick}
          handleConfirmChanges={handleConfirmChanges}
          handleDeleteClick={handleDeleteClick}
        />
      </TableCell>
    )}
  </TableRow>
)

export default TableRowItem
