import React from 'react'

import { TableHead, TableRow, TableCell } from '@mui/material'

const TableHeader = ({ tabName }) => (
  <TableHead>
    <TableRow>
      <TableCell>Nombre</TableCell>
      <TableCell align="center">Proyecto</TableCell>
      <TableCell align="center">Semana</TableCell>
      <TableCell align="center">Porcentaje acumulado</TableCell>
      {tabName !== 'Ver historial de registros' && (
        <TableCell align="right">Acciones</TableCell>
      )}
    </TableRow>
  </TableHead>
)

export default TableHeader
