import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import Papa from 'papaparse'
import dayjs from 'dayjs'
import { DataSalariesTable } from './components/DataSalariesTable'
import Swal from 'sweetalert2'
import { createSalarieQuery } from '../../config/axios'

const SalariesPage = () => {
  const [data, setData] = useState([])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await createSalarieQuery(
        '/salaries-api/registrar-salario',
        data,
        localStorage.getItem('token')
      )
      Swal.fire({
        icon: 'success',
        title: '¡Registro exitoso!',
        text: response.data.message || 'Ingreso registrado correctamente',
      })

      setData([])
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: 'No se ha registrado el ingreso correctamente',
      })
    }
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (file && file.type === 'text/csv') {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
          const updatedData = result.data
            .map((row) => {
              if (row.user_id && row.salarie && row.detail && row.date) {
                return {
                  ...row,
                  created_at: currentDate,
                  updated_at: currentDate,
                }
              }
              return row
            })
            .filter(
              (row) => row.user_id && row.salarie && row.detail && row.date
            )
          setData(updatedData)
        },
        error: (error) => {
          console.error('Error reading CSV file:', error)
        },
      })
    } else {
      console.error('Please upload a CSV file.')
    }
  }

  

  const handleDownload = () => {
    window.location.href =
      'https://nestconsultores.cl/wp-content/uploads/2024/07/CSV-Salarios.csv'
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Registrar Sueldos</Typography>
          </div>
          <div>
            <Grid item={true} container display="flex">
              <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
                <CardHeader
                  title="Registra sueldos subiendo archivo .csv"
                  subheader={
                    <>
                      Recuerda que los campos de este archivo deben ser aquellos
                      entregados (sino lo tienes,
                      <Button
                        variant="text"
                        onClick={handleDownload}
                        style={{ textTransform: 'none' }}
                      >
                        descárgalo acá
                      </Button>
                      )
                    </>
                  }
                />
                <CardContent sx={{ pt: 0 }}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                  >
                    Subir Archivo
                    <VisuallyHiddenInput
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                    />
                  </Button>
                  {data.length > 0 && <DataSalariesTable data={data} />}
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Crear
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default SalariesPage
