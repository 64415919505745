import React from 'react'

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import { formatDate } from '../../utils/formattedDate'
import BoxInfo from '../../components/common/BoxInfo'

export const ProjectDetailsData = ({ project }) => {
  const {
    client_name,
    category_name,
    project_description,
    type_name,
    created_at,
    project_status,
    total_dedicated = 'No tiene',
  } = project

  // Creamos una tarjeta con todos los detalles.
  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader title="Información general del proyecto" />
      <CardContent>
        <Box>
          <Grid container>
            <BoxInfo title="Cliente" info={client_name} />
            <BoxInfo title="Categoría del proyecto" info={category_name} />
            <BoxInfo
              title="Descripción del proyecto"
              info={project_description}
            />
            <BoxInfo title="Tipo de proyecto" info={type_name} />
            <BoxInfo title="Fecha de inicio" info={formatDate(created_at)} />
            <BoxInfo title="Estado del proyecto" info={project_status} />
            <BoxInfo
              title="Horas totales de dedicación"
              info={total_dedicated}
            />
          </Grid>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }} />
    </Card>
  )
}
